import preset from './preset';

const config = {
  ...preset,
  href: 'https://badkreuznach.chgrs.link/widget',
  theme: {
    ...preset.theme,
    background:
      'linear-gradient(90deg, rgba(0,165,225,1) 0%, rgba(200,210,50,1) 100%)',
    whiteLabelColor: '#fff',
    poweredByColor: '#fff',
    textColor: '#fff',
  },
  trophyIconPath: 'widget-badkreuznach-trophy.svg',
  treeIconPath: 'widget-badkreuznach-tree-icon.svg',
  walkingIconPath: 'widget-walking-green-theme.svg',
  cyclingIconPath: 'widget-cycling-green-theme.svg',
  co2SavedIconPath: 'widget-co2-green-theme.svg',
  publicTransitPath: 'widget-public-transit-green-theme.svg',
  activityTypes: {
    walking: true,
    cycling: true,
    co2Saved: true,
    treesPlanted: true,
    publicTransit: true,
  },
};

export default config;
