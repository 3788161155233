import preset from './preset';

const config = {
  ...preset,
  href: 'https://allianz.chgrs.link/widget',
  theme: {
    ...preset.theme,
    background: '#BCEDED',
    whiteLabelColor: '#3C3C3C',
    poweredByColor: '#3C3C3C',
    textColor: '#3C3C3C',
  },
  trophyIconPath: 'widget-trophy-light-blue-theme.svg',
  treeIconPath: 'widget-tree-light-blue-theme.svg',
  walkingIconPath: 'widget-walking-light-blue-theme.svg',
  cyclingIconPath: 'widget-cycling-light-blue-theme.svg',
  co2SavedIconPath: 'widget-co2-light-blue-theme.svg',
  publicTransitPath: 'widget-public-transit-light-blue-theme.svg',
  activityTypes: {
    walking: true,
    cycling: true,
    co2Saved: true,
    treesPlanted: true,
    publicTransit: false,
  },
};

export default config;
