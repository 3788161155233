module.exports = {
  DEBUG: true,

  // the domain for this App
  domain: 'maroshi.test',
  adminDomain: 'admin.maroshi.test',
  worldDomain: 'open.maroshi.test',
  globalDomain: 'global-challenge.maroshi.test',

  serverTimezone: 'Europe/Berlin',

  storage: {
    locale: 'changers:locale',
    authSession: 'changers:auth_session',
    uiState: 'changers:ui_state',
  },

  apiEndpoint: 'https://sst.maroshi.de',
  apiStatsEndpoint: 'https://statistics.maroshi.de',
  apiTrackingEndpoint: 'https://activity.maroshi.de',
  apiCoinStageEndpoint: 'https://api.coin-stage.de',
  awsBucketUrl: 'https://changers-sst-dev.s3.eu-central-1.amazonaws.com',

  // disable Google Analytics for development
  analytics: {
    enabled: false,
    sendHitTask: false,
    trackingId: '',
  },

  useApplanga: true,
};
