/**
 * SST Admin Interface configuration
 */

module.exports = {
  DEBUG: false,

  // show/hide DevTools, toggle visibility with ctrl+h
  devTools: false,

  // the domain for this App
  domain: 'changers.com',
  landingPageUrl: 'https://changers.com',
  adminDomain: 'admin.changers.com',
  worldDomain: 'open.changers.com',
  globalDomain: 'global-challenge.changers.com',

  serverTimezone: 'Europe/Berlin',

  storage: {
    locale: 'changers:locale',
    authSession: 'changers:auth_session',
    uiState: 'changers:ui_state',
  },

  apiEndpoint: 'https://sst.changers.com',
  apiStatsEndpoint: 'https://statistics.changers.com',
  apiTrackingEndpoint: 'https://activity.changers.com',
  apiCoinStageEndpoint: 'https://api.klima-taler.com',
  awsBucketUrl: 'https://changers-sst.s3.eu-central-1.amazonaws.com',

  stripeKey: 'pk_test_Vu9Zk9VMoD4TBH3OE1zxHiWR',
  mixpanelKey: '9668792778f7ed5536e3f48bf9fe5aa6',
  GOOGLE_MANAGER_ID: 'UA-24657524-12',

  // deprecated
  analytics: {
    enabled: false,

    // will output more verbose information to the console
    traceDebugging: false,

    // if you do not want to send data to Google Analytics in certain cases (e.g. development or testing environments),
    // you can disable the sendHitTask task and nothing will be sent
    sendHitTask: true, // always true for production

    // the tracking ID for your Google Analytics property,
    // https://support.google.com/analytics/answer/1032385
    trackingId: '',

    // the _ga cookie domain
    // https://developers.google.com/analytics/devguides/collection/analyticsjs/cookies-user-id
    cookieDomain: 'auto',
  },

  domPurifyGlobalOptions: {
    ADD_TAGS: ['iframe', 'oembed'],
    ADD_ATTR: ['frameborder', 'allowfullscreen', 'target', 'url'],
  },

  useApplanga: true,
  applanga: {
    app: '59a6a4462a86905eaf16eb14',
    token: '59a6a4462a86905eaf16eb14!927ec02d3ffdf87cf23d8fe15180b636',
  },
};
