import preset from './preset';

const config = {
  ...preset,
  href: 'https://hagen.chgrs.link/widget',
  theme: {
    ...preset.theme,
    background: '#FFFFFF',
    whiteLabelColor: '#3C3C3C',
    poweredByColor: '#3C3C3C',
    textColor: '#3C3C3C',
  },
  activityTypes: {
    walking: true,
    cycling: true,
    co2Saved: true,
    treesPlanted: true,
    publicTransit: false,
  },
};

export default config;
