import { Colors } from '../../../src/constants';

const config = {
  href: 'https://changers.chgrs.link/widget',
  theme: {
    background: Colors.WIDGET_BACKGROUND,
    whiteLabelColor: Colors.WIDGET_WHITELABEL,
    poweredByColor: Colors.WIDGET_POWERED_BY,
    textColor: Colors.WIDGET_TEXT,
    fontFamily: {
      default: 'Open Sans, sans-serif',
      ja:
        '"ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", Osaka, メイリオ, Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", "ＭＳ ゴシック" , "MS Gothic", "Noto Sans CJK JP", TakaoPGothic, sans-serif',
    },
  },
  trophyIconPath: 'widget-trophy-white-theme.svg',
  treeIconPath: 'widget-tree-white-theme.svg',
  donationIconPath: 'widget-donation-white-theme.svg',
  walkingIconPath: 'widget-walking-white-theme.svg',
  cyclingIconPath: 'widget-cycling-white-theme.svg',
  co2SavedIconPath: 'widget-co2-white-theme.svg',
  publicTransitPath: 'widget-public-transit-white-theme.svg',
  customActivityIconPath: 'widget-custom-activity-white-theme.svg',
  trainIconPath: 'widget-train-white-theme.svg',
  eCarIconPath: 'widget-ecar-white-theme.svg',
  carIconPath: 'widget-car-white-theme.svg',
  meteringWaterIconPath: 'widget-metering-water-white-theme.svg',
  meteringElectricityIconPath: 'widget-metering-electricity-white-theme.svg',
  meteringGasIconPath: 'widget-metering-gas-white-theme.svg',
  planeIconPath: 'widget-plane-white-theme.svg',
  recoinsEarnedIconPath: 'widget-recoins-earned-white-theme.svg',
  recoinsSpentIconPath: 'widget-recoins-spent-white-theme.svg',
  co2EmissionsIconPath: 'widget-co2-emissions-white-theme.svg',
  teamRankingIconPath: 'widget-team-ranking-white-theme.svg',
  usersIconPath: 'widget-users-white-theme.svg',
  activityTypes: {
    walking: true,
    cycling: true,
    co2Saved: true,
    treesPlanted: true,
    publicTransit: false,
    donations: false,
  },
  translations: {},
};

export default config;
