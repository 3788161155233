import { assign } from 'lodash';
import defaultConf from './default';
import development from './development';
import production from './production';
import productionDevelop from './production-develop';
import productionStaging from './production-staging';
import localDevelopment from './local-development';
import local from './local';

const getConfig = () => {
  let extraConfig = {};

  switch (process.env.NODE_ENV) {
    case 'production':
      extraConfig = production;
      break;
    case 'development':
      extraConfig = development;
      break;
    case 'local-development':
      extraConfig = localDevelopment;
      break;
    case 'production-develop':
      extraConfig = productionDevelop;
      break;
    case 'production-staging':
      extraConfig = productionStaging;
      break;
    case 'local':
      extraConfig = local;
      break;
  }
  return assign(defaultConf, extraConfig);
};

const config = getConfig();
config.baseConfig = assign({}, config);

export default config;
