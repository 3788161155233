// This file name is production-staging.js for a reason
// When building for our staging environment we want the NODE_ENV=production
// in order React and webpack optimize and minify the code

module.exports = {
  domain: 'changersdev.de',
  adminDomain: 'admin.changersdev.de',
  worldDomain: 'open.changersdev.de',
  globalDomain: 'global-challenge.changersdev.de',

  apiEndpoint: 'https://sst.changersdev.de',
  apiStatsEndpoint: 'https://statistics.changersdev.de',
  apiTrackingEndpoint: 'https://activity.changersdev.de',
  apiCoinStageEndpoint: 'https://api.coin-stage.de',
  awsBucketUrl: 'https://changers-sst-dev.s3.eu-central-1.amazonaws.com',
  pusherKey: 'cacda1986087b8748205',
  pusherCluster: 'eu',
  stripeKey: 'pk_test_Vu9Zk9VMoD4TBH3OE1zxHiWR',
  mixpanelKey: '704d6a553282c65468951014ba52b652',

  analytics: {
    enabled: false,

    // the tracking ID for your Google Analytics property,
    // https://support.google.com/analytics/answer/1032385
    trackingId: '',

    // the _ga cookie domain
    // https://developers.google.com/analytics/devguides/collection/analyticsjs/cookies-user-id
    cookieDomain: 'admin.changersdev.de',
  },
  useApplanga: true,
};
