module.exports = {
  DEBUG: false,

  stripeKey: 'pk_live_NqZjXga4prSBBwkup23gdGag',
  pusherKey: '2ea25d78dccf940cc757',
  pusherCluster: 'eu',
  domain: 'changers.com',
  adminDomain: 'admin.changers.com',
  worldDomain: 'open.changers.com',
  globalDomain: 'global-challenge.changers.com',
  mixpanelKey: '77ae20aec5c8bf85b92c9d105755db4c',
  analytics: {
    enabled: true,

    // the tracking ID for your Google Analytics property,
    // https://support.google.com/analytics/answer/1032385
    trackingId: 'UA-24657524-12',
  },
};
