import darmstadt from './darmstadt';
import muenster from './muenster';
import jt from './jt';
import duesseldorf from './duesseldorf';
import badkreuznach from './badkreuznach';
import allianz from './allianz';
import bielefeld from './bielefeld';
import hagen from './hagen';
import dresden from './dresden';
import psd from './psd';
import hamm from './hamm';
import aachen from './aachen';
import klimataler from './klimataler';
import preset from './preset';

export const getConfig = ({ organization }) => {
  const organisationApp = organization ? organization.toLowerCase() : '';
  switch (organisationApp) {
    case 'darmstadt':
      return darmstadt;
    case 'muenster':
      return muenster;
    case 'jt':
      return jt;
    case 'duesseldorf':
      return duesseldorf;
    case 'badkreuznach':
      return badkreuznach;
    case 'allianz':
      return allianz;
    case 'bielefeld':
      return bielefeld;
    case 'hagen':
      return hagen;
    case 'dresden':
      return dresden;
    case 'psd':
      return psd;
    case 'hamm':
      return hamm;
    case 'klima-taler':
      return klimataler;
    case 'aachen':
      return aachen;
    default:
      return preset;
  }
};
