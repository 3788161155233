import preset from './preset';

const config = {
  ...preset,
  href: 'https://klimataler.chgrs.link/widget',
  activityTypes: {
    walking: true,
    cycling: true,
    co2Saved: true,
    treesPlanted: false,
    publicTransit: true,
    customActivity: true,
    users: true,
  },
  trophyIconPath: 'widget-trophy-white-theme.svg',
  treeIconPath: 'widget-tree-white-theme.svg',
  donationIconPath: 'widget-donation-white-theme.svg',
  walkingIconPath: 'widget-klimataler-walking.svg',
  cyclingIconPath: 'widget-klimataler-cycling.svg',
  co2SavedIconPath: 'widget-co2-white-theme.svg',
  publicTransitPath: 'widget-public-transit-white-theme.svg',
  customActivityIconPath: 'widget-custom-activity-white-theme.svg',
  trainIconPath: 'widget-train-white-theme.svg',
  eCarIconPath: 'widget-ecar-white-theme.svg',
  carIconPath: 'widget-car-white-theme.svg',
  meteringWaterIconPath: 'widget-metering-water-white-theme.svg',
  meteringElectricityIconPath: 'widget-metering-electricity-white-theme.svg',
  meteringGasIconPath: 'widget-metering-gas-white-theme.svg',
  planeIconPath: 'widget-plane-white-theme.svg',
  recoinsEarnedIconPath: 'widget-recoins-earned-white-theme.svg',
  recoinsSpentIconPath: 'widget-recoins-spent-white-theme.svg',
  co2EmissionsIconPath: 'widget-co2-emissions-white-theme.svg',
  teamRankingIconPath: 'widget-team-ranking-white-theme.svg',
  usersIconPath: 'widget-users-white-theme.svg',
  translations: {
    poweredByLabel: 'iframeWidget.organisation.poweredByKlimaTalerLabel2',
  },
};

export default config;
