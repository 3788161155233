import preset from './preset';

const config = {
  ...preset,
  href: 'https://darmstadt.chgrs.link/widget',
  theme: {
    ...preset.theme,
    background: '#0C2C68',
    whiteLabelColor: '#fff',
    poweredByColor: '#fff',
    textColor: '#fff',
    fontFamily: {
      default: "'Titillium Web', sans-serif",
    },
  },
  trophyIconPath: 'widget-darmstadt-trophy.svg',
  treeIconPath: 'widget-tree-green-theme.svg',
  walkingIconPath: 'widget-walking-green-theme.svg',
  cyclingIconPath: 'widget-cycling-green-theme.svg',
  co2SavedIconPath: 'widget-co2-green-theme.svg',
  publicTransitPath: 'widget-public-transit-green-theme.svg',
  activityTypes: {
    walking: true,
    cycling: true,
    co2Saved: true,
    treesPlanted: true,
    publicTransit: true,
  },
  translations: {
    poweredByLabel: 'iframeWidget.organisation.poweredByKlimaTalerLabel',
  },
};

export default config;
