import preset from './preset';

const config = {
  ...preset,
  href: 'https://hamm.chgrs.link/widget',
  theme: {
    ...preset.theme,
    background: '#421826',
    whiteLabelColor: '#FFFFFF',
    poweredByColor: '#FFFFFF',
    textColor: '#FFFFFF',
  },
  activityTypes: {
    walking: true,
    cycling: true,
    co2Saved: true,
    treesPlanted: true,
    publicTransit: true,
  },
  trophyIconPath: 'widget-hamm-trophy.svg',
  treeIconPath: 'widget-hamm-tree.svg',
  walkingIconPath: 'widget-hamm-walking.svg',
  cyclingIconPath: 'widget-hamm-cycling.svg',
  co2SavedIconPath: 'widget-hamm-co2.svg',
  publicTransitPath: 'widget-hamm-public-transit.svg',
  recoinsEarnedIconPath: 'widget-hamm-recoins-earned.svg',
  recoinsSpentIconPath: 'widget-hamm-recoins-spent.svg',
  translations: {
    poweredByLabel: 'iframeWidget.organisation.poweredByKlimaTalerLabel',
    treesPlantedLabel: 'iframeWidget.organisation.hamm.treesPlantedLabel',
  },
};

export default config;
