import preset from './preset';

const config = {
  ...preset,
  href: 'https://jt.chgrs.link/widget',
  theme: {
    ...preset.theme,
    background: '#BCEDED',
    whiteLabelColor: '#3C3C3C',
    poweredByColor: '#3C3C3C',
    textColor: '#3C3C3C',
    fontFamily: {
      default:
        '"ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", Osaka, メイリオ, Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", "ＭＳ ゴシック" , "MS Gothic", "Noto Sans CJK JP", TakaoPGothic, sans-serif',
    },
  },
  trophyIconPath: 'widget-jt-trophy.svg',
  treeIconPath: 'widget-tree-green-theme.svg',
  walkingIconPath: 'widget-walking-green-theme.svg',
  cyclingIconPath: 'widget-cycling-green-theme.svg',
  co2SavedIconPath: 'widget-co2-green-theme.svg',
  publicTransitPath: 'widget-public-transit-green-theme.svg',
  activityTypes: {
    walking: true,
    cycling: true,
    co2Saved: true,
    treesPlanted: false,
    publicTransit: true,
  },
  translations: {},
};

export default config;
