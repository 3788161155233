import { defineMessages } from 'react-intl';

export default defineMessages({
  'iframeWidget.organisation.organisationDisplayName': {
    id: 'iframeWidget.organisation.organisationDisplayName',
    defaultMessage: '{organisation} for a better world!',
    description: 'iFrame Widget: iFrame Widget Text',
  },
  'iframeWidget.organisation.challengeYear': {
    id: 'iframeWidget.organisation.challengeYear',
    defaultMessage: 'Challenge {year}',
    description: 'iFrame Widget: iFrame Widget Text',
  },
  'iframeWidget.organisation.usersLabel': {
    id: 'iframeWidget.organisation.usersLabel',
    defaultMessage: 'User count',
    description: 'iFrame Widget: iFrame Widget Text',
  },
  'iframeWidget.organisation.runningLabel': {
    id: 'iframeWidget.organisation.runningLabel',
    defaultMessage: 'Walking KM',
    description: 'iFrame Widget: iFrame Widget Text',
  },
  'iframeWidget.organisation.cyclingLabel': {
    id: 'iframeWidget.organisation.cyclingLabel',
    defaultMessage: 'Cycling KM',
    description: 'iFrame Widget: iFrame Widget Text',
  },
  'iframeWidget.organisation.publicTransitLabel': {
    id: 'iframeWidget.organisation.publicTransitLabel',
    defaultMessage: 'Public Transit KM',
    description: 'iFrame Widget: iFrame Widget Text',
  },
  'iframeWidget.organisation.trainLabel': {
    id: 'iframeWidget.organisation.trainLabel',
    defaultMessage: 'Train KM',
    description: 'iFrame Widget: iFrame Widget Text',
  },
  'iframeWidget.organisation.eCarLabel': {
    id: 'iframeWidget.organisation.eCarLabel',
    defaultMessage: 'eCar KM',
    description: 'iFrame Widget: iFrame Widget Text',
  },
  'iframeWidget.organisation.meteringWaterLabel': {
    id: 'iframeWidget.organisation.meteringWaterLabel',
    defaultMessage: 'Water saving liters',
    description: 'iFrame Widget: iFrame Widget Text',
  },
  'iframeWidget.organisation.meteringElectricityLabel': {
    id: 'iframeWidget.organisation.meteringElectricityLabel',
    defaultMessage: 'Electricity saving kWh',
    description: 'iFrame Widget: iFrame Widget Text',
  },
  'iframeWidget.organisation.meteringGasLabel': {
    id: 'iframeWidget.organisation.meteringGasLabel',
    defaultMessage: 'Heat saving kWh',
    description: 'iFrame Widget: iFrame Widget Text',
  },
  'iframeWidget.organisation.carLabel': {
    id: 'iframeWidget.organisation.carLabel',
    defaultMessage: 'Car KM',
    description: 'iFrame Widget: iFrame Widget Text',
  },
  'iframeWidget.organisation.planeLabel': {
    id: 'iframeWidget.organisation.planeLabel',
    defaultMessage: 'Flights KM',
    description: 'iFrame Widget: iFrame Widget Text',
  },
  'iframeWidget.organisation.co2EmissionsLabel': {
    id: 'iframeWidget.organisation.co2EmissionsLabel',
    defaultMessage: 'CO2 Emissions kg',
    description: 'iFrame Widget: iFrame Widget Text',
  },
  'iframeWidget.organisation.recoinsEarnedLabel': {
    id: 'iframeWidget.organisation.recoinsEarnedLabel',
    defaultMessage: 'KT Earned',
    description: 'iFrame Widget: iFrame Widget Text',
  },
  'iframeWidget.organisation.recoinsSpentLabel': {
    id: 'iframeWidget.organisation.recoinsSpentLabel',
    defaultMessage: 'KT Spent',
    description: 'iFrame Widget: iFrame Widget Text',
  },
  'iframeWidget.organisation.teamRankingLabel': {
    id: 'iframeWidget.organisation.teamRankingLabel',
    defaultMessage: 'Team Rank {month}',
    description: 'iFrame Widget: iFrame Widget Text',
  },
  'iframeWidget.organisation.customActivityLabel': {
    id: 'iframeWidget.organisation.customActivityLabel',
    defaultMessage: 'Climate Actions',
    description: 'iFrame Widget: iFrame Widget Text',
  },
  'iframeWidget.organisation.co2SavedLabel': {
    id: 'iframeWidget.organisation.co2SavedLabel',
    defaultMessage: 'C02 Saved KG',
    description: 'iFrame Widget: iFrame Widget Text',
  },
  'iframeWidget.organisation.treesPlantedLabel': {
    id: 'iframeWidget.organisation.treesPlantedLabel',
    defaultMessage: 'Trees planted',
    description: 'iFrame Widget: iFrame Widget Text',
  },
  'iframeWidget.organisation.amountDonatedLabel': {
    id: 'iframeWidget.organisation.amountDonatedLabel',
    defaultMessage: 'Amount donated',
    description: 'iFrame Widget: iFrame Widget Text',
  },
  'iframeWidget.organisation.poweredByLabel': {
    id: 'iframeWidget.organisation.poweredByLabel',
    defaultMessage: 'By Changers.com',
    description: 'iFrame Widget: iFrame Widget Text',
  },
  'iframeWidget.organisation.poweredByKlimaTalerLabel': {
    id: 'iframeWidget.organisation.poweredByKlimaTalerLabel',
    defaultMessage: 'Klima-Taler.com',
    description: 'iFrame Widget: iFrame Widget Text',
  },
  'iframeWidget.organisation.poweredByKlimaTalerLabel2': {
    id: 'iframeWidget.organisation.poweredByKlimaTalerLabel2',
    defaultMessage: '{date}, Klima-Taler.com',
    description: 'iFrame Widget: iFrame Widget Text',
  },
  /* Custom translations */
  'iframeWidget.organisation.muenster.organisationDisplayName': {
    id: 'iframeWidget.organisation.muenster.organisationDisplayName',
    defaultMessage: 'Münster bewegt',
    description: 'iFrame Widget: iFrame Widget Text',
  },
  'iframeWidget.organisation.muenster.runningLabel': {
    id: 'iframeWidget.organisation.muenster.runningLabel',
    defaultMessage: 'Walking KM',
    description: 'iFrame Widget: iFrame Widget Text',
  },
  'iframeWidget.organisation.hamm.treesPlantedLabel': {
    id: 'iframeWidget.organisation.hamm.treesPlantedLabel',
    defaultMessage: 'sqm flower meadow',
    description: 'iFrame Widget: iFrame Widget Text',
  },
});
