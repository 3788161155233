import preset from './preset';

const config = {
  ...preset,
  href: 'https://psd.chgrs.link/widget',
  theme: {
    ...preset.theme,
  },
  activityTypes: {
    walking: true,
    cycling: true,
    co2Saved: true,
    treesPlanted: false,
    publicTransit: true,
    donations: true,
  },
};

export default config;
