/**
 * Used from UIActions and UI reducer for namespacing
 */
const UIViews = {
  COMMON: 'common',
  LOADING_CURTAIN: 'loadingCurtain',
};

const Forms = {
  LOGIN_FORM: 'organization/login-form',
  REGISTER_ORGANISATION_FORM: 'organization/register-form',
  ORGANISATION_ACTIVATION_PASSWORD_FORM:
    'organization/activation/password-form',
  ORGANISATION_ACTIVATION_DETAILS_FORM: 'organization/activation/details-form',
  ORGANISATION_ACCESS_CODE_FORM: 'organization/access-code/form',
  REGISTER_USER_FORM: 'user/register-form',
  USER_PROFILE_FORM: 'user/profile-form',
  USER_PROFILE_CHANGE_EMAIL_FORM: 'user/profile-change-email-form',
  REGISTER_USER_MATCH_ORGANISATION_FORM:
    'user/register-form/match-organisation',
  FORGOT_PASSWORD_FORM: 'organization/forgot-password-form',
  RESET_PASSWORD_FORM: 'organization/reset-password-form',
  ONBOARDING_FORM: 'welcome/onboarding-form',
  ORGANIZATION_FORM: 'organization/organization-form',
  ORGANIZATION_BILLING_DETAILS_FORM:
    'organization/organization-billing-details-form',
  ORGANIZATION_SETTINGS_FORM: 'organization/organization-settings-form',
  ORGANIZATION_SETTINGS_ABOUT_INFO_FORM:
    'organization/organization-settings-about-info-form',
  ORGANIZATION_SETTINGS_PRIVACY_POLICY_FORM:
    'organization/organization-settings-privacy-policy-form',
  ORGANIZATION_SETTINGS_FAQ_FORM: 'organization/organization-settings-faq-form',
  ORGANIZATION_SETTINGS_TERMS_OF_SERVICE_FORM:
    'organization/organization-settings-terms-of-service-form',
  ORGANIZATION_LOGO_FORM: 'organization/organization-logo-form',
  CHALLENGE_FORM: 'organization/challenge-form',
  ADD_ADMIN_EMAIL_FORM: 'admin/add-admin-email-form',
  ORGANISATION_ENTITIES_FORM: 'organisation/organisation-entities-form',
  TEAM_TYPES_FORM: 'teams/team-types-form',
  TEAMS_UPLOAD_IMAGE_FORM: 'teams/teams-upload-image-form',
  TEAMS_FORM: 'teams/teams-form',
  TEAMS_JOIN_FORM: 'teams/join-form',
  TREE_PLANTING_FORM: 'charity/tree-planting-form',
  TREE_PLANTING_PARTNER_FORM: 'charity/tree-planting-partner-form',
  CHARITY_LINKING_FORM: 'charity/linking-form',
  DONATION_FORM: 'charity/donation-form',
  DONATION_PROJECT_FORM: 'charity/donation-project-form',
  ADD_CUSTOM_ACTIVITIES_FORM: 'custom-activities/custom-activities-form',
  TRACK_MOBILITIES_FORM: 'dashboard/track-mobilities-form',
  TRACK_CUSTOM_ACTIVITIES_FORM: 'dashboard/track-custom-activities-form',
  VOUCHERS_FORM: 'rewards/vouchers-form',
  RECOINS_FORM: 'recoins/recoins-form',
  VOUCHERS_UPLOAD_IMAGE_FORM: 'rewards/vouchers-upload-image-form',
  LOTTERIES_FORM: 'rewards/lotteries-form',
  REFERRAL_SETTINGS_FORM: 'referral-settings/form',
  DYNAMIC_LINK_SETTINGS_FORM: 'dynamic-link-settings/form',
  DYNAMIC_LINK_SETTINGS_UPLOAD_IMAGE_FORM:
    'dynamic-link-settings/upload-image-form',
  LOTTERIES_UPLOAD_IMAGE_FORM: 'rewards/lotteries-upload-image-form',
  COMPENSATION_UPLOAD_IMAGE_FORM:
    'compensation-partners/compensation-upload-image-form',
  COMMUNICATION_FORM: 'communication/communication-form',
  COMMUNICATION_UPLOAD_IMAGE_FORM:
    'communication/communication-upload-image-form',
  IMAGE_UPLOAD_MODAL_FORM: 'util/image-upload-modal-form',
  COMPENSATION_PARTNER_FORM:
    'compensation-partner/add-compensation-partner-form',
  COMPENSATION_FORM: 'compensation/add-compensation-form',
  COMPENSATION_PARTNER_IMAGE_FORM: 'compensation-partner/image-upload-form',
  USER_REFERRAL_INVITE_FORM: 'communication/user-referral-invite-form',
  REPORT_FORM: 'report/report-form',
  CHALLENGE_REPORT_FORM: 'report/challenge-report-form',
  EDITORIAL_FORM: 'editorial/editorial-form',
  WIDGET_SETTINGS_FORM: 'organisastion/widget-settings-form',
  PROMO_CODE_FORM: 'billing/promo-code-form',
  ORGANISATION_ONBOARD_FORM: 'organisation/onboard',
};

/**
  APIFetch value naming has the existing convention

  `.`: is being used so as to lookup if data exist in redux state.entities
  `__`: whatever follows after `__` is being ignored for the redux lookup and is used only for naming
*/
const APIFetch = {
  CURRENT_USER: 'users__currentUser',
  USERS: 'users',
  USER_STATS: 'dashboards.user',
  USER_ACTIVITY_STATS: 'dashboards.userStats',
  USER_CHARITY_STATS: 'dashboards.userCharityStats',
  ORGANISATIONS: 'organisations',
  ORGANISATIONS_ADMIN_DETAILS: 'organisations__adminDetails',
  ORGANISATION_ACTIVITY_STATS: 'dashboards.organisationStats',
  ORGANISATION_CHALLENGES_STATS: 'dashboards.challengesStats',
  ORGANISATION_INFO_STATS: 'dashboards.infoStats',
  ORGANISATION_CHARITY_STATS: 'dashboards.charityStats',
  GLOBAL_STATS: 'dashboards.global',
  CHALLENGES: 'challenges',
  CHARITIES: 'charities',
  CURRENT_USER_TEAMS: 'currentUserTeams',
  TEAM_TYPES: 'teamTypes',
  TEAM_TYPES_ALL_TEAMS: 'teams__byTeamType',
  TEAMS: 'teams',
  ORGANISATION_ENTITIES: 'organisationEntities',
  TREE_TYPES: 'treeTypes',
  TREE_PLANTINGS: 'treePlantings',
  TREE_PLANTING_PARTNERS: 'treePlantingPartners',
  DONATIONS: 'donations',
  DONATION_PROJECTS: 'donationProjects',
  ACTIVITIES: 'activities__nonrestricted',
  RESTRICTED_ACTIVITIES: 'activities__restricted',
  ARCHIVED_ACTIVITIES: 'activities__archived',
  LOTTERIES: 'lotteries',
  LOTTERIES_TAGS: 'lotteries.tags',
  VOUCHERS: 'vouchers',
  VOUCHERS_TAGS: 'vouchers.tags',
  LOTTERIES_ARCHIVED: 'lotteries_archived',
  VOUCHERS_ARCHIVED: 'vouchers_archived',
  ACTIVITY_CATEGORIES: 'activityCategories',
  CLIMATE_PARTNERS: 'climatePartners',
  EDITORIALS: 'editorials',
  COMPENSATION_PARTNERS: 'compensationPartners',
  REPORTS: 'reports',
  USER_INVITES: 'user_invites',
};

// Form modes
const FormModes = {
  NEW: 'new',
  VIEW: 'view',
  EDIT: 'edit',
};

// Modes
const Modes = {
  FIELD_HAS_VALUE: 'field_has_value',
};

const LocalizableFieldsDefaultLanguage = 'en';

const LocalizableFieldsLanguages = {
  en: 'English',
  de: 'German',
  cz: 'Czech',
  es: 'Spanish',
  pt: 'Portuguese',
  fr: 'French',
  hu: 'Hungarian',
  it: 'Italian',
  nl: 'Dutch',
  pl: 'Polish',
  sk: 'Slovakian',
  ja: 'Japanese',
  fi: 'Finnish',
  sv: 'Swedish',
};

const LocalizableIframeLanguages = {
  en: 'EN',
  de: 'DE',
  pt: 'PT',
  ja: 'JA',
};

const Currencies = {
  1: 'EUR',
  2: 'USD',
  3: 'GBP',
};

const DynamicLinkTypes = {
  REFERRAL: 'referral',
  CUSTOM_ACTIVITY: 'custom_activity',
  RECOGNITION: 'recognition',
};

const Colors = {
  WIDGET_BACKGROUND: '#FFFFFF',
  WIDGET_WHITELABEL: '#3C3C3C',
  WIDGET_POWERED_BY: '#000000',
  WIDGET_TEXT: '#000000',
};

const Regex = {
  EMAIL_VALIDATION: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i,
};

const AUDIO_FILE_TYPE = 'audio';
const ORGANISATIONS_LIST_PAGINATION_LIMIT = 50;
const ORGANISATIONS_LIST_ITEMS_TO_DISPLAY = 10;
const ORGANISATIONS_LIST_DEFAULT_PAGE = 1;

const COMPENSATION_PARTNERS_LIST_PAGINATION_LIMIT = 100;
const COMPENSATION_PARTNERS_LIST_DEFAULT_PAGE = 1;

const COMPENSATION_LIST_PAGINATION_LIMIT = 100;
const COMPENSATION_LIST_DEFAULT_PAGE = 1;

const TIME = {
  SECONDS_IN_HOUR: 60 * 60,
  SECONDS_IN_MINUTES: 60,
};

const ImageTypes = {
  ICON_TYPE: 'icon',
  IMAGE_TYPE: 'image',
  LOGO_TYPE: 'logo',
  BACKGROUND_TYPE: 'background',
};

const ImageCategories = {
  DONATION_PROJECT_IMAGE: 'donation_project',
  COMPENSATION_IMAGE: 'compensation',
  COMPENSATION_PARTNER_IMAGE: 'compensation_partner',
  CUSTOM_ACTIVITY_IMAGE: 'custom_activity',
  HABIT_BUILDER_IMAGE: 'habit_builder',
  LOTTERY_IMAGE: 'lottery',
  VOUCHER_IMAGE: 'voucher',
  ORGANISATION_IMAGE: 'organisation',
  USER_IMAGE: 'user',
  PUSH_NOTIFICATION: 'push_notification',
  SOCIAL_POST_IMAGE: 'post',
  TEAM_IMAGE: 'team',
  IFRAME_WIDGET_IMAGE: 'iframe_widget',
};

const EditorialOrganisationTypes = {
  SAAS: 'saas',
  SDK: 'sdk',
  SDK_INTEGRATED: 'sdk_integrated',
  WHITELABEL: 'whitelabel',
};

const EditorialSlugs = {
  DESCRIPTION: 'description',
  FAQ: 'faq',
  PRIVACY_POLICY: 'privacy_policy',
  TERMS_OF_SERVICE: 'terms_of_service',
  COMPENSATION_DESCRIPTION: 'compensation_description',
  METERING_DESCRIPTION: 'metering_description',
  SAVING_WATER: 'saving_water',
  WATER_SAVING_TIPS: 'water_saving_tips',
  SAVING_ELECTRICITY: 'saving_electricity',
  ELECTRICITY_SAVING_TIPS: 'electricity_saving_tips',
  SAVING_GAS: 'saving_gas',
  GAS_SAVING_TIPS: 'gas_saving_tips',
  HOME_OFFICE_CALCULATION_INFO_TEXT: 'home_office_calculation_info_text',
  HOME_OFFICE_CALCULATION_COMMUTE_INFO_TEXT:
    'home_office_calculation_commute_info_text',
  CO2_OFFSET_INFO_TEXT: 'co2_offset_info_text',
  CO2_CONTRIBUTION_INFO_TEXT: 'co2_contribution_info_text',
  CUSTOM_ACTIVITY_INFO_TEXT: 'custom_activity_info_text',
};

const LANGUAGE_DROPDOWN_HEIGHT = '12rem';

const ReferralRewardTypes = {
  RECOIN: 1,
};

const VOUCHERS_LIST_DEFAULT_PAGE = 1;
const VOUCHERS_LIST_ITEMS_TO_DISPLAY = 10;
const VOUCHERS_LIST_PAGINATION_LIMIT = 20;

const USER_VOUCHER_TYPE_ID = 1;
const USER_VOUCHERS_LIST_DEFAULT_PAGE = 1;
const USER_VOUCHERS_LIST_ITEMS_TO_DISPLAY = 10;
const USER_VOUCHERS_LIST_PAGINATION_LIMIT = 10;

export {
  APIFetch,
  UIViews,
  Modes,
  Forms,
  FormModes,
  LocalizableFieldsDefaultLanguage,
  LocalizableFieldsLanguages,
  LocalizableIframeLanguages,
  Currencies,
  Colors,
  DynamicLinkTypes,
  AUDIO_FILE_TYPE,
  TIME,
  COMPENSATION_PARTNERS_LIST_DEFAULT_PAGE,
  COMPENSATION_PARTNERS_LIST_PAGINATION_LIMIT,
  COMPENSATION_LIST_PAGINATION_LIMIT,
  COMPENSATION_LIST_DEFAULT_PAGE,
  LANGUAGE_DROPDOWN_HEIGHT,
  ImageTypes,
  ImageCategories,
  EditorialOrganisationTypes,
  EditorialSlugs,
  ORGANISATIONS_LIST_PAGINATION_LIMIT,
  ORGANISATIONS_LIST_ITEMS_TO_DISPLAY,
  ORGANISATIONS_LIST_DEFAULT_PAGE,
  ReferralRewardTypes,
  Regex,
  VOUCHERS_LIST_DEFAULT_PAGE,
  VOUCHERS_LIST_ITEMS_TO_DISPLAY,
  VOUCHERS_LIST_PAGINATION_LIMIT,
  USER_VOUCHER_TYPE_ID,
  USER_VOUCHERS_LIST_DEFAULT_PAGE,
  USER_VOUCHERS_LIST_ITEMS_TO_DISPLAY,
  USER_VOUCHERS_LIST_PAGINATION_LIMIT,
};
