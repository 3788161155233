// This file name is production-staging.js for a reason
// When building for our staging environment we want the NODE_ENV=production
// in order React and webpack optimize and minify the code

module.exports = {
  DEBUG: true,
  domain: 'maroshi.de',
  adminDomain: 'admin.maroshi.de',
  worldDomain: 'open.maroshi.de',
  globalDomain: 'global-challenge.maroshi.de',

  apiEndpoint: 'https://sst.maroshi.de',
  apiStatsEndpoint: 'https://statistics.maroshi.de',
  apiTrackingEndpoint: 'https://activity.maroshi.de',
  apiCoinStageEndpoint: 'https://api.coin-stage.de',
  awsBucketUrl: 'https://changers-sst-stage.s3.eu-central-1.amazonaws.com',
  pusherKey: '2e76a902506272bb21ac',
  pusherCluster: 'eu',
  stripeKey: 'pk_test_Vu9Zk9VMoD4TBH3OE1zxHiWR',
  mixpanelKey: '9668792778f7ed5536e3f48bf9fe5aa6',

  analytics: {
    enabled: false,

    // the tracking ID for your Google Analytics property,
    // https://support.google.com/analytics/answer/1032385
    trackingId: '',

    // the _ga cookie domain
    // https://developers.google.com/analytics/devguides/collection/analyticsjs/cookies-user-id
    cookieDomain: 'admin.maroshi.de',
  },
  useApplanga: true,
};
