import preset from './preset';

const config = {
  ...preset,
  href: 'https://aachen.chgrs.link/widget',
  activityTypes: {
    walking: true,
    cycling: true,
    co2Saved: true,
    treesPlanted: true,
    publicTransit: true,
  },
  translations: {
    poweredByLabel: 'iframeWidget.organisation.poweredByKlimaTalerLabel',
  },
};

export default config;
