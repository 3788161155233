import preset from './preset';

const config = {
  ...preset,
  href: 'https://dresden.chgrs.link/widget',
  theme: {
    ...preset.theme,
    background: '#5AE6BE',
    whiteLabelColor: '#00505A',
    poweredByColor: '#00505A',
    textColor: '#00505A',
  },
  trophyIconPath: 'widget-dresden-trophy.svg',
  treeIconPath: 'widget-dresden-tree-icon.svg',
  walkingIconPath: 'widget-dresden-walking.svg',
  cyclingIconPath: 'widget-dresden-cycling.svg',
  co2SavedIconPath: 'widget-dresden-co2.svg',
  publicTransitPath: 'widget-dresden-public-transit.svg',
  activityTypes: {
    walking: true,
    cycling: true,
    co2Saved: true,
    treesPlanted: false,
    publicTransit: true,
  },
  disabledPoweredBy: true,
};

export default config;
