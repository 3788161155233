/**
 * SST Admin Interface configuration
 */

module.exports = {
    DEBUG: false,
    devTools: true,
    domain: 'changersdev.test',
    adminDomain: 'admin.changersdev.test',
    worldDomain: 'open.changersdev.test',
    globalDomain: 'global-challenge.changersdev.test',
  
    apiEndpoint: 'https://sst.changersdev.test',
    apiStatsEndpoint: 'https://statistics.changersdev.test',
    apiTrackingEndpoint: 'https://activity.changersdev.test',
    apiCoinStageEndpoint: 'https://api.coin-stage.de',

    stripeKey: 'pk_test_Vu9Zk9VMoD4TBH3OE1zxHiWR',
    
    analytics: {
        enabled: false,

        // the tracking ID for your Google Analytics property,
        // https://support.google.com/analytics/answer/1032385
        trackingId: '',

        // the _ga cookie domain
        // https://developers.google.com/analytics/devguides/collection/analyticsjs/cookies-user-id
        cookieDomain: 'admin.changersdev.test',
    },
};
  