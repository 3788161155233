import preset from './preset';

const config = {
  ...preset,
  href: 'https://bielefeld.chgrs.link/widget',
  theme: {
    ...preset.theme,
    background: '#a2c510',
  },
  activityTypes: {
    walking: false,
    cycling: false,
    co2Saved: true,
    treesPlanted: true,
    publicTransit: false,
  },
};

export default config;
