import React, { useEffect, useState } from 'react'; // eslint-disable-line no-unused-vars
import ReactDOM from 'react-dom';
import axios from 'axios';

import styled, { ThemeProvider } from 'styled-components';
import 'typeface-titillium-web';

import config from 'config';
import translations from './translations';
import { addLocaleData, FormattedMessage, IntlProvider } from 'react-intl';
import locale_en from 'react-intl/locale-data/en';
import locale_de from 'react-intl/locale-data/de';
import locale_pt from 'react-intl/locale-data/pt';
import locale_ja from 'react-intl/locale-data/ja';
import { getConfig } from './config/organizations';
import PropTypes from 'prop-types';

addLocaleData([...locale_en, ...locale_de, ...locale_pt, ...locale_ja]);

const consoleError = console.error.bind(console);
// eslint-disable-next-line
console.error = (message, ...args) => {
  if (
    typeof message === 'string' &&
    message.startsWith('[React Intl] Missing message:')
  ) {
    return;
  }
  consoleError(message, ...args);
};

const ChangersWrapper = styled.a`
  text-decoration: none;
`;

const ChangersContainer = styled.div.attrs(() => ({
  className: 'changers-container',
}))`
  background: ${props =>
    props.theme.overrides.backgroundColor
      ? props.theme.overrides.backgroundColor
      : props.theme.background};
  color: ${props =>
    props.theme.overrides.textColor
      ? props.theme.overrides.textColor
      : props.theme.textColor};
  flex-wrap: wrap;
  justify-content: space-between;
  font-family: ${props =>
    props.theme.fontFamily[props.lang]
      ? props.theme.fontFamily[props.lang]
      : props.theme.fontFamily.default};
  margin: 0;
  position: relative;
  padding-bottom: 12px;
  padding-top: 12px;
  display: ${props => (props.hidden ? 'none' : 'flex')};

  .show-year {
    &.with-subtitle {
      margin-left: 0.3rem;
    }
  }
`;

const WhiteLabel = styled.div`
  color: ${props =>
    props.theme.overrides.textColor
      ? props.theme.overrides.textColor
      : props.theme.whiteLabelColor};
`;

const PoweredBy = styled.div`
  color: ${props =>
    props.theme.overrides.textColor
      ? props.theme.overrides.textColor
      : props.theme.poweredByColor};
  font-size: 12px;
`;

const OrganizationDataContainer = styled.div.attrs(() => ({
  className: 'changers-data-container',
}))`
  float: right;
  margin-left: 20px;
`;

const OrganizationActivity = styled(WhiteLabel).attrs(() => ({
  className: 'changers-activity-name',
}))`
  font-size: 16px;
`;

const ActivityNumbers = styled(WhiteLabel).attrs(() => ({
  className: 'changers-activity-numbers',
}))`
  font-size: 16px;
  font-weight: bold;
`;

const PoweredBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: absolute;
  right: 20px;
  bottom: 5px;
`;

const ChangersItem = styled.div.attrs(() => ({
  className: 'changers-item',
}))`
  margin: 10px;
  display: flex;
  align-items: center;
  text-decoration: none;

  @media only screen and (max-width: 992px) {
    min-width: 25%;
  }

  img.icon-image {
    height: 60px;
    width: auto;
  }
`;
const language = navigator.language.split(/[-_]/)[0];
const AvailableLanguages = ['en', 'de', 'pt', 'ja'];
const DefaultLanguage = 'en';

const ChangersWidget = props => {
  const { overrides = {} } = props;
  const {
    title,
    subtitle,
    showYear,
    trophyIcon: settingsTrophyIcon,
    iframeLink,
    lang,
    hideTrees,
  } = overrides;

  const [visible] = useState(true);
  const [hasWidgetLoaded, setHasWidgetLoaded] = useState(false);
  const [organisationData, setOrganisationData] = useState({});
  const [widgetConfig, setWidgetConfig] = useState(
    getConfig({ organization: '' })
  );
  const [organisationWidgetConfig, setOrganisationWidgetConfig] = useState({
    widgetConfig: '',
  });

  const urlProtocol = 'https://';
  const trophyIcon = settingsTrophyIcon
    ? settingsTrophyIcon
    : `${urlProtocol}${config.adminDomain}/${widgetConfig.trophyIconPath}`;
  const treeIcon = `${urlProtocol}${config.adminDomain}/${widgetConfig.treeIconPath}`;
  const donationIcon = `${urlProtocol}${config.adminDomain}/${widgetConfig.donationIconPath}`;
  const walkingIcon = `${urlProtocol}${config.adminDomain}/${widgetConfig.walkingIconPath}`;
  const cyclingIcon = `${urlProtocol}${config.adminDomain}/${widgetConfig.cyclingIconPath}`;
  const co2SavedIcon = `${urlProtocol}${config.adminDomain}/${widgetConfig.co2SavedIconPath}`;
  const publicTransitIcon = `${urlProtocol}${config.adminDomain}/${widgetConfig.publicTransitPath}`;
  const customActivityIcon = `${urlProtocol}${config.adminDomain}/${widgetConfig.customActivityIconPath}`;
  const usersIcon = `${urlProtocol}${config.adminDomain}/${widgetConfig.usersIconPath}`;
  const trainIcon = `${urlProtocol}${config.adminDomain}/${widgetConfig.trainIconPath}`;
  const eCarIcon = `${urlProtocol}${config.adminDomain}/${widgetConfig.eCarIconPath}`;
  const carIcon = `${urlProtocol}${config.adminDomain}/${widgetConfig.carIconPath}`;
  const meteringWaterIcon = `${urlProtocol}${config.adminDomain}/${widgetConfig.meteringWaterIconPath}`;
  const meteringElectricityIcon = `${urlProtocol}${config.adminDomain}/${widgetConfig.meteringElectricityIconPath}`;
  const meteringGasIcon = `${urlProtocol}${config.adminDomain}/${widgetConfig.meteringGasIconPath}`;
  const planeIcon = `${urlProtocol}${config.adminDomain}/${widgetConfig.planeIconPath}`;
  const recoinsEarnedIcon = `${urlProtocol}${config.adminDomain}/${widgetConfig.recoinsEarnedIconPath}`;
  const recoinsSpentIcon = `${urlProtocol}${config.adminDomain}/${widgetConfig.recoinsSpentIconPath}`;
  const co2EmissionsIcon = `${urlProtocol}${config.adminDomain}/${widgetConfig.co2EmissionsIconPath}`;
  const teamRankingIcon = `${urlProtocol}${config.adminDomain}/${widgetConfig.teamRankingIconPath}`;

  useEffect(() => {
    axios
      .get(
        `${config.apiTrackingEndpoint}/api/statistics/organisations/${props.organisationId}/widget`,
        { params: { team_id: props?.teamId } }
      )
      .then(({ data }) => {
        setOrganisationData(data);
      });
  }, []);

  useEffect(() => {
    const publicUrl = organisationData.public_url;

    const organization = publicUrl ? publicUrl.split('.')[0] : '';
    setWidgetConfig(getConfig({ organization }));
    setOrganisationWidgetConfig(organisationData.widget_config);
    if (organisationData.id) {
      setHasWidgetLoaded(true);
    }
  }, [organisationData]);

  widgetConfig.theme.overrides = overrides;

  return hasWidgetLoaded ? (
    <ThemeProvider theme={widgetConfig.theme}>
      <ChangersWrapper href={iframeLink || widgetConfig.href} target="_blank">
        <ChangersContainer
          className={`changers-container lang-${lang}`}
          hidden={!visible || !organisationData}
          lang={lang}
        >
          <ChangersItem
            className="changers-item"
            style={{ fontWeight: 'bold' }}
          >
            <img
              src={
                props?.useLogo && organisationData.logo
                  ? organisationData.logo
                  : trophyIcon
              }
              className="icon-image"
            />
            <OrganizationDataContainer>
              <WhiteLabel>
                {title || (
                  <FormattedMessage
                    {...translations[
                      widgetConfig.translations.organisationDisplayName ||
                        'iframeWidget.organisation.organisationDisplayName'
                    ]}
                    values={{
                      organisation: organisationData.display_name,
                    }}
                  />
                )}
              </WhiteLabel>
              <WhiteLabel>
                {subtitle && <span>{subtitle}</span>}
                {showYear && (
                  <span
                    className={`show-year ${subtitle ? 'with-subtitle' : ''}`}
                  >
                    {new Date().getFullYear()}
                  </span>
                )}
              </WhiteLabel>
            </OrganizationDataContainer>
          </ChangersItem>
          {/* <!-- Users --> */}
          {organisationWidgetConfig.users && (
            <ChangersItem>
              <img src={usersIcon} className="icon-image" />
              <OrganizationDataContainer>
                <OrganizationActivity>
                  <FormattedMessage
                    {...translations[
                      widgetConfig.translations.usersLabel ||
                        'iframeWidget.organisation.usersLabel'
                    ]}
                  />
                </OrganizationActivity>
                <ActivityNumbers>
                  {Math.round(organisationData.users).toLocaleString(language)}
                </ActivityNumbers>
              </OrganizationDataContainer>
            </ChangersItem>
          )}
          {/* <!-- Running activity --> */}
          {organisationWidgetConfig.walking && (
            <ChangersItem>
              <img src={walkingIcon} className="icon-image" />
              <OrganizationDataContainer>
                <OrganizationActivity>
                  <FormattedMessage
                    {...translations[
                      widgetConfig.translations.runningLabel ||
                        'iframeWidget.organisation.runningLabel'
                    ]}
                  />
                </OrganizationActivity>
                <ActivityNumbers>
                  {Math.round(organisationData.walking / 1000).toLocaleString(
                    language
                  )}
                </ActivityNumbers>
              </OrganizationDataContainer>
            </ChangersItem>
          )}

          {/* <!-- Cycling --> */}
          {organisationWidgetConfig.cycling && (
            <ChangersItem>
              <img src={cyclingIcon} className="icon-image" />
              <OrganizationDataContainer>
                <OrganizationActivity>
                  <FormattedMessage
                    {...translations['iframeWidget.organisation.cyclingLabel']}
                  />
                </OrganizationActivity>
                <ActivityNumbers>
                  {Math.round(organisationData.cycling / 1000).toLocaleString(
                    language
                  )}
                </ActivityNumbers>
              </OrganizationDataContainer>
            </ChangersItem>
          )}

          {/* <!-- Public Transit --> */}
          {organisationWidgetConfig.public_transit && (
            <ChangersItem>
              <img src={publicTransitIcon} className="icon-image" />
              <OrganizationDataContainer>
                <OrganizationActivity>
                  <FormattedMessage
                    {...translations[
                      'iframeWidget.organisation.publicTransitLabel'
                    ]}
                  />
                </OrganizationActivity>
                <ActivityNumbers>
                  {Math.round(
                    organisationData.public_transit / 1000
                  ).toLocaleString(language)}
                </ActivityNumbers>
              </OrganizationDataContainer>
            </ChangersItem>
          )}

          {/* <!-- Train --> */}
          {organisationWidgetConfig.train && (
            <ChangersItem>
              <img src={trainIcon} className="icon-image" />
              <OrganizationDataContainer>
                <OrganizationActivity>
                  <FormattedMessage
                    {...translations['iframeWidget.organisation.trainLabel']}
                  />
                </OrganizationActivity>
                <ActivityNumbers>
                  {Math.round(organisationData.train / 1000).toLocaleString(
                    language
                  )}
                </ActivityNumbers>
              </OrganizationDataContainer>
            </ChangersItem>
          )}

          {/* <!-- eCar --> */}
          {organisationWidgetConfig.ecar && (
            <ChangersItem>
              <img src={eCarIcon} className="icon-image" />
              <OrganizationDataContainer>
                <OrganizationActivity>
                  <FormattedMessage
                    {...translations['iframeWidget.organisation.eCarLabel']}
                  />
                </OrganizationActivity>
                <ActivityNumbers>
                  {Math.round(organisationData.ecar / 1000).toLocaleString(
                    language
                  )}
                </ActivityNumbers>
              </OrganizationDataContainer>
            </ChangersItem>
          )}

          {/* <!-- Metering Water --> */}
          {organisationWidgetConfig.metering_water && (
            <ChangersItem>
              <img src={meteringWaterIcon} className="icon-image" />
              <OrganizationDataContainer>
                <OrganizationActivity>
                  <FormattedMessage
                    {...translations[
                      'iframeWidget.organisation.meteringWaterLabel'
                    ]}
                  />
                </OrganizationActivity>
                <ActivityNumbers>
                  {Math.round(organisationData.metering_water).toLocaleString(
                    language
                  )}
                </ActivityNumbers>
              </OrganizationDataContainer>
            </ChangersItem>
          )}

          {/* <!-- Metering Electricity --> */}
          {organisationWidgetConfig.metering_electricity && (
            <ChangersItem>
              <img src={meteringElectricityIcon} className="icon-image" />
              <OrganizationDataContainer>
                <OrganizationActivity>
                  <FormattedMessage
                    {...translations[
                      'iframeWidget.organisation.meteringElectricityLabel'
                    ]}
                  />
                </OrganizationActivity>
                <ActivityNumbers>
                  {Math.round(
                    organisationData.metering_electricity
                  ).toLocaleString(language)}
                </ActivityNumbers>
              </OrganizationDataContainer>
            </ChangersItem>
          )}

          {/* <!-- Metering Gas --> */}
          {organisationWidgetConfig.metering_gas && (
            <ChangersItem>
              <img src={meteringGasIcon} className="icon-image" />
              <OrganizationDataContainer>
                <OrganizationActivity>
                  <FormattedMessage
                    {...translations[
                      'iframeWidget.organisation.meteringGasLabel'
                    ]}
                  />
                </OrganizationActivity>
                <ActivityNumbers>
                  {Math.round(organisationData.metering_gas).toLocaleString(
                    language
                  )}
                </ActivityNumbers>
              </OrganizationDataContainer>
            </ChangersItem>
          )}

          {/* <!-- Car --> */}
          {organisationWidgetConfig.car && (
            <ChangersItem>
              <img src={carIcon} className="icon-image" />
              <OrganizationDataContainer>
                <OrganizationActivity>
                  <FormattedMessage
                    {...translations['iframeWidget.organisation.carLabel']}
                  />
                </OrganizationActivity>
                <ActivityNumbers>
                  {Math.round(organisationData.car / 1000).toLocaleString(
                    language
                  )}
                </ActivityNumbers>
              </OrganizationDataContainer>
            </ChangersItem>
          )}

          {/* <!-- Plane --> */}
          {organisationWidgetConfig.plane && (
            <ChangersItem>
              <img src={planeIcon} className="icon-image" />
              <OrganizationDataContainer>
                <OrganizationActivity>
                  <FormattedMessage
                    {...translations['iframeWidget.organisation.planeLabel']}
                  />
                </OrganizationActivity>
                <ActivityNumbers>
                  {Math.round(organisationData.plane / 1000).toLocaleString(
                    language
                  )}
                </ActivityNumbers>
              </OrganizationDataContainer>
            </ChangersItem>
          )}

          {/* <!-- CO2 Emissions --> */}
          {organisationWidgetConfig.co2_emissions && (
            <ChangersItem>
              <img src={co2EmissionsIcon} className="icon-image" />
              <OrganizationDataContainer>
                <OrganizationActivity>
                  <FormattedMessage
                    {...translations[
                      'iframeWidget.organisation.co2EmissionsLabel'
                    ]}
                  />
                </OrganizationActivity>
                <ActivityNumbers>
                  {Math.round(
                    organisationData.co2_emissions / 1000
                  ).toLocaleString(language)}
                </ActivityNumbers>
              </OrganizationDataContainer>
            </ChangersItem>
          )}

          {/* <!-- Custom Activity --> */}
          {organisationWidgetConfig.custom_activity && (
            <ChangersItem>
              <img src={customActivityIcon} className="icon-image" />
              <OrganizationDataContainer>
                <OrganizationActivity>
                  <FormattedMessage
                    {...translations[
                      'iframeWidget.organisation.customActivityLabel'
                    ]}
                  />
                </OrganizationActivity>
                <ActivityNumbers>
                  {Math.round(organisationData.custom_activity).toLocaleString(
                    language
                  )}
                </ActivityNumbers>
              </OrganizationDataContainer>
            </ChangersItem>
          )}

          {/* <!-- Recoins Earned --> */}
          {organisationWidgetConfig.recoins_earned && (
            <ChangersItem>
              <img src={recoinsEarnedIcon} className="icon-image" />
              <OrganizationDataContainer>
                <OrganizationActivity>
                  <FormattedMessage
                    {...translations[
                      'iframeWidget.organisation.recoinsEarnedLabel'
                    ]}
                  />
                </OrganizationActivity>
                <ActivityNumbers>
                  {Math.round(organisationData.recoins_earned).toLocaleString(
                    language
                  )}
                </ActivityNumbers>
              </OrganizationDataContainer>
            </ChangersItem>
          )}

          {/* <!-- Recoins Spent --> */}
          {organisationWidgetConfig.recoins_spent && (
            <ChangersItem>
              <img src={recoinsSpentIcon} className="icon-image" />
              <OrganizationDataContainer>
                <OrganizationActivity>
                  <FormattedMessage
                    {...translations[
                      'iframeWidget.organisation.recoinsSpentLabel'
                    ]}
                  />
                </OrganizationActivity>
                <ActivityNumbers>
                  {Math.round(organisationData.recoins_spent).toLocaleString(
                    language
                  )}
                </ActivityNumbers>
              </OrganizationDataContainer>
            </ChangersItem>
          )}

          {/* <!-- C02 --> */}
          {organisationWidgetConfig.co2_avoided && (
            <ChangersItem>
              <img src={co2SavedIcon} className="icon-image" />
              <OrganizationDataContainer>
                <OrganizationActivity>
                  <FormattedMessage
                    {...translations['iframeWidget.organisation.co2SavedLabel']}
                  />
                </OrganizationActivity>
                <ActivityNumbers>
                  {Math.round(
                    organisationData.co2_avoided / 1000
                  ).toLocaleString(language)}
                </ActivityNumbers>
              </OrganizationDataContainer>
            </ChangersItem>
          )}

          {/* <!-- Trees --> */}
          {organisationWidgetConfig.trees && !hideTrees && (
            <ChangersItem>
              <img src={treeIcon} className="icon-image" />
              <OrganizationDataContainer>
                <OrganizationActivity>
                  <FormattedMessage
                    {...translations[
                      widgetConfig.translations.treesPlantedLabel ||
                        'iframeWidget.organisation.treesPlantedLabel'
                    ]}
                  />
                </OrganizationActivity>
                <ActivityNumbers>
                  {Math.round(organisationData.trees).toLocaleString(language)}
                </ActivityNumbers>
              </OrganizationDataContainer>
            </ChangersItem>
          )}
          {/* <!-- Donations --> */}
          {organisationWidgetConfig.donations && (
            <ChangersItem>
              <img src={donationIcon} className="icon-image" />
              <OrganizationDataContainer>
                <OrganizationActivity>
                  <FormattedMessage
                    {...translations[
                      'iframeWidget.organisation.amountDonatedLabel'
                    ]}
                  />
                </OrganizationActivity>
                <ActivityNumbers>
                  {Math.round(organisationData.donations).toLocaleString(
                    language
                  )}
                </ActivityNumbers>
              </OrganizationDataContainer>
            </ChangersItem>
          )}

          {/* <!-- Team Ranking --> */}
          {organisationWidgetConfig.team_ranking && (
            <ChangersItem>
              <img src={teamRankingIcon} className="icon-image" />
              <OrganizationDataContainer>
                <OrganizationActivity>
                  <FormattedMessage
                    {...translations[
                      'iframeWidget.organisation.teamRankingLabel'
                    ]}
                    values={{
                      month: new Date().toLocaleDateString(lang, {
                        month: 'long',
                      }),
                    }}
                  />
                </OrganizationActivity>
                <ActivityNumbers>
                  {Math.round(organisationData.team_ranking).toLocaleString(
                    language
                  )}
                </ActivityNumbers>
              </OrganizationDataContainer>
            </ChangersItem>
          )}
          {/* <!-- Powered by --> */}
          {!widgetConfig.disabledPoweredBy && (
            <PoweredBlock>
              <PoweredBy>
                <FormattedMessage
                  {...translations[
                    widgetConfig.translations.poweredByLabel ||
                      'iframeWidget.organisation.poweredByLabel'
                  ]}
                  values={{
                    date: new Date().toLocaleDateString(lang),
                  }}
                />
              </PoweredBy>
            </PoweredBlock>
          )}
        </ChangersContainer>
      </ChangersWrapper>
    </ThemeProvider>
  ) : (
    <div />
  );
};
ChangersWidget.propTypes = {
  overrides: PropTypes.object,
  organisationId: PropTypes.number,
  teamId: PropTypes.number,
  useLogo: PropTypes.bool,
  locale: PropTypes.string,
};

class IntlLoader extends React.Component {
  constructor(props) {
    super(props);
    const { locale } = props;
    this.state = { locale, messages: {} };
  }

  componentDidMount() {
    this.fetchTranslations();
  }

  componentWillReceiveProps() {
    this.fetchTranslations();
  }

  fetchTranslations() {
    const { locale } = this.props;
    const langRequest = axios.create({
      baseURL: `https://api.applanga.com/v1/api?app=${config.applanga.app}&requestedLanguages=["${locale}"]`,
      headers: {
        Authorization: `Bearer ${config.applanga.token}`,
      },
    });
    langRequest.get().then(({ data: { data } }) => {
      const messages = data[locale].main.entries;
      Object.keys(messages).map(key => {
        messages[key] = messages[key].v;
      });
      this.setState({ messages });
    });
  }

  render() {
    return (
      <IntlProvider {...this.state}>
        <ChangersWidget
          organisationId={id}
          teamId={teamId}
          useLogo={useLogo}
          overrides={this.props.overrides || {}}
        />
      </IntlProvider>
    );
  }
}

IntlLoader.propTypes = {
  overrides: PropTypes.object,
  locale: PropTypes.string,
};

const element = document.getElementById('changers-widget');
const id = element.getAttribute('organisation-id');
const teamId = element.getAttribute('team-id');
const useLogo = element.getAttribute('use-logo');
const title = element.getAttribute('title');
const subtitle = element.getAttribute('subtitle');
const showYear = element.getAttribute('showYear') === 'true';
const backgroundColor = element.getAttribute('backgroundColor');
const textColor = element.getAttribute('textColor');
const trophyIcon = element.getAttribute('trophyIcon');
const hideTrees = element.getAttribute('hideTrees');
const iframeLink = element.getAttribute('iframeLink') || undefined;
let lang = element.getAttribute('lang');

if (!lang) {
  lang = navigator.language.split(/[-_]/)[0];
}

if (AvailableLanguages.indexOf(lang) == -1) {
  lang = DefaultLanguage;
}

const overrides = {
  title,
  subtitle,
  showYear,
  backgroundColor,
  textColor,
  trophyIcon,
  iframeLink,
  lang,
  hideTrees,
};

ReactDOM.render(<IntlLoader locale={lang} overrides={overrides} />, element);
