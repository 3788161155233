module.exports = {
  // domain: 'maroshi.test',
  // adminDomain: 'admin.maroshi.test',
  // worldDomain: 'world.maroshi.test',

  // // apiEndpoint: 'https://sst.changers.test',
  // apiEndpoint: 'https://sst.maroshi.de',
  // apiStatsEndpoint: 'https://statistics.maroshi.de',
  // apiTrackingEndpoint: 'https://activity.maroshi.de',

  DEBUG: true,
  domain: 'changersdev.test',
  adminDomain: 'admin.changersdev.test',
  worldDomain: 'open.changersdev.test',
  globalDomain: 'global-challenge.changersdev.test',

  apiEndpoint: 'https://sst.changersdev.de',
  apiStatsEndpoint: 'https://statistics.changersdev.de',
  apiTrackingEndpoint: 'https://activity.changersdev.de',
  apiCoinStageEndpoint: 'https://api.coin-stage.de',
  awsBucketUrl: 'https://changers-sst-dev.s3.eu-central-1.amazonaws.com',
  pusherKey: 'cacda1986087b8748205',
  pusherCluster: 'eu',
  mixpanelKey: '704d6a553282c65468951014ba52b652',

  //Staging switch
  // apiEndpoint: 'https://sst.maroshi.de',
  // apiStatsEndpoint: 'https://statistics.maroshi.de',
  // apiTrackingEndpoint: 'https://activity.maroshi.de',
  // awsBucketUrl: 'https://changers-sst-stage.s3.eu-central-1.amazonaws.com',
  // pusherKey: '2e76a902506272bb21ac',
  // pusherCluster: 'eu',
  // Staging switch end
  // stripeKey: 'pk_test_Vu9Zk9VMoD4TBH3OE1zxHiWR',

  // Prod switch
  // apiEndpoint: 'https://sst.changers.com',
  // apiStatsEndpoint: 'https://statistics.changers.com',
  // apiTrackingEndpoint: 'https://activity.changers.com',
  // apiCoinStageEndpoint: 'https://api.klima-taler.com',
  // awsBucketUrl: 'https://changers-sst.s3.eu-central-1.amazonaws.com',
  // pusherKey: 'cacda1986087b8748205',
  // pusherCluster: 'eu',
  // mixpanelKey: '704d6a553282c65468951014ba52b652',

  useApplanga: true,

  // analytics: {
  //   enabled: true,
  //
  //   // the tracking ID for your Google Analytics property,
  //   // https://support.google.com/analytics/answer/1032385
  //   trackingId: '',
  //
  //   // the _ga cookie domain
  //   // https://developers.google.com/analytics/devguides/collection/analyticsjs/cookies-user-id
  //   cookieDomain: 'sst.changers.test',
  // },
};
